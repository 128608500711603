/* eslint-disable */
import React from 'react'

import DoctorTablet from '../../images/people/doctor-smiling-with-tablet.jpg'
import DoctorTabletMobile from '../../images/people/doctor-smiling-with-tablet-mobile.jpg'

import CoupleCouchLaptop from '../../images/people/couple-couch-laptop.jpg'
import CoupleCouchLaptopMobile from '../../images/people/couple-couch-laptop-mobile.jpg'
import vibrantDarkBlueBackground from '../../images/backgrounds/vibrant-dark-blue.jpg'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import humana from '../../images/brand-logos/humana.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import united from '../../images/brand-logos/united-healthcare.svg'

import NarrowBanner from '../../components/NarrowBanner'

import {
  Billboard,
  Carousel,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'

const Home = () => {
  const { rotatedNumber } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Find an in-network doctor near you | Clearlinkinsurance.com',
          description:
            'Find and book in-network healthcare providers near you.',
          canonical: 'https://clearlinkinsurance.com/',
          robots: 'follow,index',
        },
        path: '/find/find-a-doctor',
        promoCode: '169959',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Find an in-network doctor near you | Clearlinkinsurance.com',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-split-content">
          <div className="wrapper">
            <Billboard
              backgroundColor="white"
              alignImageToBottom
              variant="split"
              image={
                <img
                  src={DoctorTablet}
                  alt="a doctor smiles while holding a tablet"
                />
              }
              mobileImage={
                <img
                  src={DoctorTabletMobile}
                  alt="a doctor smiles while holding a tablet"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Find a doctor in-network near you
                  </Typography>
                  <Typography variant="h5">
                    Clearlink Insurance Agency makes it easy to find general
                    practitioners and other doctors who accept your plan’s
                    coverage.
                  </Typography>
                  <LinkButton
                    variant="feature"
                    to="#find-a-doctor"
                    color="pink"
                  >
                    Find a Doctor
                  </LinkButton>
                </>
              }
            />
          </div>
        </div>

        <div className="dual-button-banner" id="find-a-doctor">
          <NarrowBanner
            backgroundColor="#F004B9"
            leftButton={
              <LinkButton to={`tel:${rotatedNumber}`} color="white">
                <span className="mobile-text">Call Now</span>
                <span className="desktop-text">
                  Call Now to Speak to an Agent {rotatedNumber}
                </span>
              </LinkButton>
            }
            rightButton={
              <LinkButton
                to="https://my.clearlinkinsurance.com/login"
                color="white"
              >
                User Portal Login
              </LinkButton>
            }
          />
        </div>

        <div className="hide-nav-button">
          <VariableContent
            backgroundColor="light"
            mainContent={
              <>
                <Typography variant="h2">Find a doctor</Typography>
                <Typography variant="body">
                  Click or tap the button associated with your insurance carrier
                  to visit their doctor search page. From there, you can find
                  the selection of available physicians covered by your plan.
                </Typography>
              </>
            }
          >
            <Stack spacing="l">
              <Columns cardStyled mobileCarousel>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={aetna}
                    alt="Aetna logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.aetnamedicare.com/en/find-doctors-hospitals/find-provider.html"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={cigna}
                    alt="Cigna logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://hcpdirectory.cigna.com/web/public/consumer/directory/search?consumerCode=HDC056"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={humana}
                    alt="Humana logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://finder.humana.com/finder/medical?customerId=1"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={united}
                    alt="United Healthcare logo"
                    style={{ maxWidth: '200px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://connect.werally.com/plans/uhc"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
                <Column
                  backgroundColor="white"
                  className="centered-flex-columns"
                >
                  <img
                    src={wellcare}
                    alt="Wellcare logo"
                    style={{ maxWidth: '90px' }}
                    className="margin-x-auto margin-bottom-24"
                  />
                  <LinkButton
                    to="https://www.wellcare.com/en/fap"
                    color="secondary"
                    outlined
                    className="margin-x-auto"
                  >
                    Search
                  </LinkButton>
                </Column>
              </Columns>
            </Stack>
          </VariableContent>
        </div>

        <SplitContent
          className="reverse-desktop"
          backgroundColor="white"
          alignImageToBottom={false}
          image={
            <img
              src={CoupleCouchLaptop}
              alt="a couple sits on couch together and looks at laptop"
            />
          }
          mobileImage={
            <img
              src={CoupleCouchLaptopMobile}
              alt="a couple sits on couch together and looks at laptop"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">How to search for a doctor</Typography>
              <Typography variant="body">
                When you click or tap the link associated with your insurance
                carrier, you’ll arrive at their “find a doctor” page.
              </Typography>
              <Typography variant="body">
                From there, search by location or health need to find the
                healthcare provider you’re looking for.
              </Typography>
              <Typography variant="body">
                To complete your search, you may need to input the following
                information:
              </Typography>
              <List>
                <ListItem>Medicare ID card</ListItem>
                <ListItem>Plan year and type</ListItem>
                <ListItem>Home address</ListItem>
                <ListItem>ZIP code</ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          style={{
            backgroundImage: `url(${vibrantDarkBlueBackground})`,
            color: '#fff',
          }}
          alignMainContent="Center"
          mainContent={
            <>
              <Typography variant="h1">Find specialized care</Typography>
              <Typography variant="body">
                Not looking for a primary care doctor? Use the button below to
                return to our Medicare provider search hub. There, you can
                search for hospitals, dentists, and other providers covered by
                your plan.
              </Typography>
              <LinkButton
                variant=""
                to="https://my.clearlinkinsurance.com/login"
                color="pink"
              >
                Find a healthcare provider
              </LinkButton>
            </>
          }
        ></VariableContent>

        <VariableContent
          alignMainContent="Center"
          className="quote-slide-carousel"
        >
          <Carousel>
            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">
                    Robert A. from Texas says
                  </Typography>
                  <Typography variant="body">
                    “...definitely went above and beyond what could be expected
                    on trying to clear up a messy situation with an insurance
                    company. When it looked like all was futile, you were able
                    to come through and provide us with the coverage we were
                    looking for.”
                  </Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>

            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">
                    Joseph A. from the U.S. says
                  </Typography>
                  <Typography variant="body">
                    “The agent was informative and helpful. A good experience.”
                  </Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>
          </Carousel>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
